import api from "./api.service";

class ManagedCompanyService {
  get PATH() {
    return "company-managedcompany";
  }

  async updatePermission(id, permission_level) {
    const res = await api.patch(`company-managedcompanies/${id}/?manager=true`, permission_level)
    return res.data
  }

}

export default new ManagedCompanyService();