<template>
  <b-modal id="user-role-modal" :title="$t('Change user role and permissions')" @hidden="onModalHidden">
    <euro-select v-model="selectedRole" :placeholder="$t('Select role')" :options="roles"></euro-select>
    <div v-if="errorMessages['permission_level']" class="d-block invalid-feedback">
      {{ errorMessages['permission_level'][0] }}
    </div>
    <div v-if="selectedRole == '0' && (getCurrentManagedCompanyPaycheckPermission || isApStaff)" class="mt-6">
      <label v-b-tooltip.hover class="checkbox checkbox-lg"
        :title="$t(`By enabling this permission, the user will be able to view and download every employees paychecks`)">
        <input v-model="paycheckPermission" type="checkbox" />
        <span class="mr-3"></span>
        {{ $t("Paycheck permission") }}
      </label>
      <div v-if="errorMessages['paycheck_permission']" class="d-block invalid-feedback">
        {{ errorMessages['paycheck_permission'][0] }}
      </div>
    </div>
    <div v-if="selectedRole == '0' && (getCurrentManagedCompanyCertificateSuitabilityPermission || isApStaff)" class="mt-6">
      <label v-b-tooltip.hover class="checkbox checkbox-lg"
        :title="$t(`By enabling this permission, the user will be able to view and download every employees medical certificate`)">
        <input v-model="certificateSuitability" type="checkbox" />
        <span class="mr-3"></span>
        {{ $t("Certificate suitability for work permission") }}
      </label>
      <div v-if="errorMessages['certificate_suitability_for_work_permission']" class="d-block invalid-feedback">
        {{ errorMessages['certificate_suitability_for_work_permission'][0] }}
      </div>
    </div>
    <div v-if="selectedRole == '0' && (getCurrentManagedCompanyContractPermission || isApStaff)" class="mt-6">
      <label v-b-tooltip.hover class="checkbox checkbox-lg"
        :title="$t(`By enabling this permission, the user will be able to view and download every employees contract`)">
        <input v-model="contractPermission" type="checkbox" />
        <span class="mr-3"></span>
        {{ $t("Contract permission") }}
      </label>
      <div v-if="errorMessages['contract_permission']" class="d-block invalid-feedback">
        {{ errorMessages['contract_permission'][0] }}
      </div>
    </div>
    <div v-if="selectedRole == '0' && (getCurrentManagedCompanyIdentityDocumentPermission || isApStaff)" class="mt-6">
      <label v-b-tooltip.hover class="checkbox checkbox-lg"
        :title="$t(`By enabling this permission, the user will be able to view and download every employees identity document`)">
        <input v-model="identityDocumentPermission" type="checkbox" />
        <span class="mr-3"></span>
        {{ $t("Identity document permission") }}
      </label>
      <div v-if="errorMessages['identity_document_permission']" class="d-block invalid-feedback">
        {{ errorMessages['identity_document_permission'][0] }}
      </div>
    </div>
    <div v-if="selectedRole == '0' && (getCurrentManagedCompanyPostingCertificatePermission || isApStaff)" class="mt-6">
      <label v-b-tooltip.hover class="checkbox checkbox-lg"
        :title="$t(`By enabling this permission, the user will be able to view and download every employees A1 certificate`)">
        <input v-model="postingCertificatePermission" type="checkbox" />
        <span class="mr-3"></span>
        {{ $t("A1 Certificate permission") }}
      </label>
      <div v-if="errorMessages['posting_certificate_permission']" class="d-block invalid-feedback">
        {{ errorMessages['posting_certificate_permission'][0] }}
      </div>
    </div>
    <template #modal-footer>
      <b-button variant="success" @click="save">
        {{ $t('Save Changes') }}
      </b-button>
      <b-button @click="closeModal">{{ $t('Cancel') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import ManagedCompanyService from "@/core/services/managedcompany.service"
import { mapGetters } from 'vuex';
import { backendErrorSwal } from "@/core/helpers/swal";
export default {

  props: {
    managedCompany: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedRole: null,
      paycheckPermission: false,
      certificateSuitability: false,
      contractPermission: false,
      postingCertificatePermission: false,
      identityDocumentPermission: false,
      company: {},
      errorMessages: [],
    }
  },

  computed: {
    ...mapGetters('user', ['getCurrentManagedCompanyId', 'isAfterSale', "getCurrentManagedCompanyPaycheckPermission", "getCurrentManagedCompanyCertificateSuitabilityPermission", "getCurrentManagedCompanyContractPermission", "getCurrentManagedCompanyIdentityDocumentPermission", "getCurrentManagedCompanyPostingCertificatePermission", "isApStaff"]),
    roles() {
      if (this.isAfterSale) {
        return [
          { value: "1", text: 'After sale' }
        ]
      }

      return [
        { value: "0", text: 'Administrator' },
        { value: "1", text: 'After sale' }
      ]
    }
  },

  watch: {
    managedCompany: {
      deep: true,
      async handler(val) {
        this.selectedRole = String(val[0].permission_level)
        this.paycheckPermission = val[0].paycheck_permission
        this.certificateSuitability = val[0].certificate_suitability_for_work_permission
        this.contractPermission = val[0].contract_permission
        this.postingCertificatePermission = val[0].posting_certificate_permission
        this.identityDocumentPermission = val[0].identity_document_permission
      }
    },
    selectedRole(val) {
      if (val == 1) {
        this.paycheckPermission = false
        this.certificateSuitability = false
        this.contractPermission = false
        this.postingCertificatePermission = false
        this.identityDocumentPermission = false
      }
    }
  },

  methods: {
    closeModal() {
      this.$bvModal.hide("user-role-modal");
    },
    onModalHidden() {
      this.selectedRole = null;
      this.errorMessages = [];
    },
    async save() {
      let companyId = this.managedCompany[0].id;
      ManagedCompanyService.updatePermission(companyId, {
        permission_level: this.selectedRole,
        paycheck_permission: this.paycheckPermission,
        certificate_suitability_for_work_permission: this.certificateSuitability,
        contract_permission: this.contractPermission,
        posting_certificate_permission: this.postingCertificatePermission,
        identity_document_permission: this.identityDocumentPermission
      }).then(() => {
        this.$emit("refresh");
        this.closeModal();
      }).catch((err) => {
        if (err.response.status === 400) {
          this.errorMessages = err.response.data;
          backendErrorSwal(err, this.$t('You do not have permission to change user role and permissions'), this.$t('Permission error'));
          return;
        }
        this.closeModal();
        console.error(err)
        backendErrorSwal(err);
        
      })
    }
  }

}
</script>