var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header flex-wrap border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title"
  }, [_c('h3', {
    staticClass: "card-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Atlasposting Users")) + " "), _c('span', {
    staticClass: "d-block text-muted pt-2 font-size-sm"
  }, [_vm._v(_vm._s(_vm.$t("Invited users")) + ": " + _vm._s(_vm.company_user_count) + " - " + _vm._s(_vm.$t("Available invites")) + ": " + _vm._s(_vm.company_available_seats))])])]), _c('div', {
    staticClass: "card-toolbar"
  }, [_c('euro-input', {
    staticClass: "min-w-300px mb-0 mr-0",
    attrs: {
      "placeholder": "Search",
      "debounce": 300
    },
    model: {
      value: _vm.searchFilter,
      callback: function callback($$v) {
        _vm.searchFilter = $$v;
      },
      expression: "searchFilter"
    }
  }), _c('div', [_c('button', {
    staticClass: "btn btn-light-primary font-weight-bolder",
    attrs: {
      "type": "button",
      "aria-haspopup": "true",
      "aria-expanded": "false"
    },
    on: {
      "click": _vm.chooseEuropostingUser
    }
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Files/User-folder.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Invite user")) + " ")])])], 1)]), _c('div', {
    staticClass: "card-body"
  }, [_c('Datatable', {
    ref: "usersTable",
    attrs: {
      "table-props": _vm.tableProps,
      "total": _vm.total,
      "options": _vm.tableOptions,
      "per-page-options": _vm.perPageOptions
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell.avatar",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('Avatar', {
          attrs: {
            "avatar-image": item === null || item === void 0 ? void 0 : item.avatar,
            "avatar-text": _vm.getInitials(item.first_name, item.last_name)
          }
        }), _c('div', {
          staticClass: "ml-3"
        }, [_c('div', {
          staticClass: "font-weight-bold text-capitalize",
          style: {
            wordBreak: 'break-word'
          }
        }, [_vm._v(" " + _vm._s("".concat(item === null || item === void 0 ? void 0 : item.first_name, " ").concat(item === null || item === void 0 ? void 0 : item.last_name)) + " ")]), _c('div', {
          staticClass: "text-muted font-size-xs"
        }, [_vm._v(_vm._s(item.email))])])], 1)];
      }
    }, {
      key: "cell.role",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(_vm.getUserRole(item)))])];
      }
    }, {
      key: "cell.permissions",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', [_c('button', {
          staticClass: "btn btn-xs btn-light-primary font-weight-bolder",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              return _vm.togglePermissions(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md"
        }, [_c('i', {
          class: item.showPermissions ? 'fas fa-eye-slash' : 'fas fa-eye'
        })]), _vm._v(" " + _vm._s(item.showPermissions ? _vm.$t("Hide Permissions") : _vm.$t("Show Permissions")) + " ")]), item.showPermissions ? _c('div', {
          style: {
            'margin-top': item.showPermissions ? '10px' : '0'
          }
        }, [_c('div', {
          staticClass: "d-flex flex-column mb-2"
        }, [item.showPermissions ? _c('div', [_c('div', {
          staticClass: "d-flex flex-column mb-2"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-2"
        }, [_c('span', {
          staticClass: "label label-dot label-xl mr-2",
          class: {
            'label-success': _vm.getUserPermissionPaychek(item),
            'label-danger': !_vm.getUserPermissionPaychek(item)
          }
        }), _c('div', {
          staticClass: "font-weight-bold text-muted mr-4"
        }, [_vm._v(_vm._s(_vm.$t("Paycheck permission")))])]), _c('div', {
          staticClass: "d-flex align-items-center mb-2"
        }, [_c('span', {
          staticClass: "label label-dot label-xl mr-2",
          class: {
            'label-success': _vm.getUserPermissionCertificate(item),
            'label-danger': !_vm.getUserPermissionCertificate(item)
          }
        }), _c('div', {
          staticClass: "font-weight-bold text-muted mr-4"
        }, [_vm._v(" " + _vm._s(_vm.$t("Certificate suitability for work permission")) + " ")])]), _c('div', {
          staticClass: "d-flex align-items-center mb-2"
        }, [_c('span', {
          staticClass: "label label-dot label-xl mr-2",
          class: {
            'label-success': _vm.getUserPermissionID(item),
            'label-danger': !_vm.getUserPermissionID(item)
          }
        }), _c('div', {
          staticClass: "font-weight-bold text-muted mr-4"
        }, [_vm._v(_vm._s(_vm.$t("Identity document permission")))])]), _c('div', {
          staticClass: "d-flex align-items-center mb-2"
        }, [_c('span', {
          staticClass: "label label-dot label-xl mr-2",
          class: {
            'label-success': _vm.getUserPermissionContract(item),
            'label-danger': !_vm.getUserPermissionContract(item)
          }
        }), _c('div', {
          staticClass: "font-weight-bold text-muted mr-4"
        }, [_vm._v(_vm._s(_vm.$t("Contract permission")))])]), _c('div', {
          staticClass: "d-flex align-items-center mb-2"
        }, [_c('span', {
          staticClass: "label label-dot label-xl mr-2",
          class: {
            'label-success': _vm.getUserPermissionPosting(item),
            'label-danger': !_vm.getUserPermissionPosting(item)
          }
        }), _c('div', {
          staticClass: "font-weight-bold text-muted mr-4"
        }, [_vm._v(_vm._s(_vm.$t("A1 certificate permission")))])])])]) : _vm._e()])]) : _vm._e()])];
      }
    }, {
      key: "cell.is_active",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          staticClass: "label label-lg font-weight-bolder label-inline text-capitalize",
          class: {
            'label-light-success': item.is_active,
            'label-light-danger': !item.is_active
          }
        }, [_vm._v(" " + _vm._s(item.is_active ? _vm.$t("ACTIVE").toLowerCase() : _vm.$t("NOT ACTIVE").toLowerCase()) + " ")])];
      }
    }, {
      key: "cell.date_joined",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', {
          staticClass: "font-weight-bold text-muted"
        }, [_vm._v(_vm._s(_vm.DateService.format(item.date_joined, _vm.dateFormat)))])];
      }
    }, {
      key: "cell.is_invite_accepted",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', {
          staticClass: "label label-lg font-weight-bolder label-inline text-capitalize",
          class: {
            'label-light-primary': item.is_invite_accepted,
            'label-light-danger': !item.is_invite_accepted
          }
        }, [_vm._v(" " + _vm._s(item.is_active ? _vm.$t("ACTIVE").toLowerCase() : _vm.$t("NOT ACTIVE").toLowerCase()) + " " + _vm._s(item.is_invite_accepted ? _vm.$t("ACCEPTED").toLowerCase() : _vm.$t("NOT ACCEPTED").toLowerCase()) + " ")])];
      }
    }, {
      key: "cell.actions",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('btn-icon', {
          attrs: {
            "icon": item.is_active ? _vm.icons.ban : _vm.icons.check,
            "tooltip": item.is_active ? _vm.$t('Disable User') : _vm.$t('Enable User'),
            "size": "xs",
            "disabled": item.id == _vm.getUserID
          },
          on: {
            "click": function click($event) {
              return _vm.toggleUser(item);
            }
          }
        }), _c('btn-icon', {
          staticClass: "ml-3",
          attrs: {
            "icon": "fas fa-user-tag",
            "tooltip": _vm.$t('Change user role and permissions'),
            "disabled": _vm.user_permission != 0 || _vm.logged_user_id == item.id,
            "size": "xs"
          },
          on: {
            "click": function click($event) {
              return _vm.changeUserRole(item);
            }
          }
        }), _c('btn-icon', {
          staticClass: "ml-3",
          attrs: {
            "icon": _vm.icons.notify,
            "tooltip": _vm.$t('Notifications settings'),
            "size": "xs"
          },
          on: {
            "click": function click($event) {
              return _vm.openNotificationsSettings(item);
            }
          }
        }), _c('div', {
          staticClass: "mt-3"
        }, [_c('btn-icon', {
          staticClass: "mr-3",
          attrs: {
            "icon": _vm.icons.sendMail,
            "tooltip": item.is_invite_accepted ? _vm.$t('Send password reset email') : _vm.$t('Send new invite email'),
            "size": "xs"
          },
          on: {
            "click": function click($event) {
              return _vm.sendNewInviteMail(item);
            }
          }
        }), _c('btn-icon', {
          attrs: {
            "icon": _vm.icons.lineAwesome.physicalPerson,
            "tooltip": _vm.$t('Go to physical person profile'),
            "size": "xs"
          },
          on: {
            "click": function click($event) {
              return _vm.goToPhysicalPerson(item);
            }
          }
        })], 1)], 1)];
      }
    }], null, true)
  }), _c('NotificationsSettingsModal', _vm._b({
    attrs: {
      "user-name": _vm.userName
    },
    on: {
      "close": _vm.closeNotificationsSettings,
      "close-refresh": _vm.closeNotificationsSettings
    }
  }, 'NotificationsSettingsModal', _vm.editNotificationsModal, false)), _c('UserRoleModal', {
    attrs: {
      "managed-company": _vm.managedCompany
    },
    on: {
      "refresh": _vm.refresh
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }