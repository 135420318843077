var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "user-role-modal",
      "title": _vm.$t('Change user role and permissions')
    },
    on: {
      "hidden": _vm.onModalHidden
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.save
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('Save Changes')) + " ")]), _c('b-button', {
          on: {
            "click": _vm.closeModal
          }
        }, [_vm._v(_vm._s(_vm.$t('Cancel')))])];
      },
      proxy: true
    }])
  }, [_c('euro-select', {
    attrs: {
      "placeholder": _vm.$t('Select role'),
      "options": _vm.roles
    },
    model: {
      value: _vm.selectedRole,
      callback: function callback($$v) {
        _vm.selectedRole = $$v;
      },
      expression: "selectedRole"
    }
  }), _vm.errorMessages['permission_level'] ? _c('div', {
    staticClass: "d-block invalid-feedback"
  }, [_vm._v(" " + _vm._s(_vm.errorMessages['permission_level'][0]) + " ")]) : _vm._e(), _vm.selectedRole == '0' && (_vm.getCurrentManagedCompanyPaycheckPermission || _vm.isApStaff) ? _c('div', {
    staticClass: "mt-6"
  }, [_c('label', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "checkbox checkbox-lg",
    attrs: {
      "title": _vm.$t("By enabling this permission, the user will be able to view and download every employees paychecks")
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.paycheckPermission,
      expression: "paycheckPermission"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.paycheckPermission) ? _vm._i(_vm.paycheckPermission, null) > -1 : _vm.paycheckPermission
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.paycheckPermission,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.paycheckPermission = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.paycheckPermission = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.paycheckPermission = $$c;
        }
      }
    }
  }), _c('span', {
    staticClass: "mr-3"
  }), _vm._v(" " + _vm._s(_vm.$t("Paycheck permission")) + " ")]), _vm.errorMessages['paycheck_permission'] ? _c('div', {
    staticClass: "d-block invalid-feedback"
  }, [_vm._v(" " + _vm._s(_vm.errorMessages['paycheck_permission'][0]) + " ")]) : _vm._e()]) : _vm._e(), _vm.selectedRole == '0' && (_vm.getCurrentManagedCompanyCertificateSuitabilityPermission || _vm.isApStaff) ? _c('div', {
    staticClass: "mt-6"
  }, [_c('label', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "checkbox checkbox-lg",
    attrs: {
      "title": _vm.$t("By enabling this permission, the user will be able to view and download every employees medical certificate")
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.certificateSuitability,
      expression: "certificateSuitability"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.certificateSuitability) ? _vm._i(_vm.certificateSuitability, null) > -1 : _vm.certificateSuitability
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.certificateSuitability,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.certificateSuitability = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.certificateSuitability = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.certificateSuitability = $$c;
        }
      }
    }
  }), _c('span', {
    staticClass: "mr-3"
  }), _vm._v(" " + _vm._s(_vm.$t("Certificate suitability for work permission")) + " ")]), _vm.errorMessages['certificate_suitability_for_work_permission'] ? _c('div', {
    staticClass: "d-block invalid-feedback"
  }, [_vm._v(" " + _vm._s(_vm.errorMessages['certificate_suitability_for_work_permission'][0]) + " ")]) : _vm._e()]) : _vm._e(), _vm.selectedRole == '0' && (_vm.getCurrentManagedCompanyContractPermission || _vm.isApStaff) ? _c('div', {
    staticClass: "mt-6"
  }, [_c('label', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "checkbox checkbox-lg",
    attrs: {
      "title": _vm.$t("By enabling this permission, the user will be able to view and download every employees contract")
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.contractPermission,
      expression: "contractPermission"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.contractPermission) ? _vm._i(_vm.contractPermission, null) > -1 : _vm.contractPermission
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.contractPermission,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.contractPermission = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.contractPermission = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.contractPermission = $$c;
        }
      }
    }
  }), _c('span', {
    staticClass: "mr-3"
  }), _vm._v(" " + _vm._s(_vm.$t("Contract permission")) + " ")]), _vm.errorMessages['contract_permission'] ? _c('div', {
    staticClass: "d-block invalid-feedback"
  }, [_vm._v(" " + _vm._s(_vm.errorMessages['contract_permission'][0]) + " ")]) : _vm._e()]) : _vm._e(), _vm.selectedRole == '0' && (_vm.getCurrentManagedCompanyIdentityDocumentPermission || _vm.isApStaff) ? _c('div', {
    staticClass: "mt-6"
  }, [_c('label', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "checkbox checkbox-lg",
    attrs: {
      "title": _vm.$t("By enabling this permission, the user will be able to view and download every employees identity document")
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.identityDocumentPermission,
      expression: "identityDocumentPermission"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.identityDocumentPermission) ? _vm._i(_vm.identityDocumentPermission, null) > -1 : _vm.identityDocumentPermission
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.identityDocumentPermission,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.identityDocumentPermission = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.identityDocumentPermission = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.identityDocumentPermission = $$c;
        }
      }
    }
  }), _c('span', {
    staticClass: "mr-3"
  }), _vm._v(" " + _vm._s(_vm.$t("Identity document permission")) + " ")]), _vm.errorMessages['identity_document_permission'] ? _c('div', {
    staticClass: "d-block invalid-feedback"
  }, [_vm._v(" " + _vm._s(_vm.errorMessages['identity_document_permission'][0]) + " ")]) : _vm._e()]) : _vm._e(), _vm.selectedRole == '0' && (_vm.getCurrentManagedCompanyPostingCertificatePermission || _vm.isApStaff) ? _c('div', {
    staticClass: "mt-6"
  }, [_c('label', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "checkbox checkbox-lg",
    attrs: {
      "title": _vm.$t("By enabling this permission, the user will be able to view and download every employees A1 certificate")
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.postingCertificatePermission,
      expression: "postingCertificatePermission"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.postingCertificatePermission) ? _vm._i(_vm.postingCertificatePermission, null) > -1 : _vm.postingCertificatePermission
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.postingCertificatePermission,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.postingCertificatePermission = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.postingCertificatePermission = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.postingCertificatePermission = $$c;
        }
      }
    }
  }), _c('span', {
    staticClass: "mr-3"
  }), _vm._v(" " + _vm._s(_vm.$t("A1 Certificate permission")) + " ")]), _vm.errorMessages['posting_certificate_permission'] ? _c('div', {
    staticClass: "d-block invalid-feedback"
  }, [_vm._v(" " + _vm._s(_vm.errorMessages['posting_certificate_permission'][0]) + " ")]) : _vm._e()]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }